import { Tooltip } from "@mui/material";
import React, { useState } from "react";

const GeneralQuestionStarRating = ({
  question,
  handleAnswer,
  find_answer,
  isDisabled,
  disableTooltip,
}) => {
  const [hoverFill, setHoverFill] = useState(null);

  const handleRating = (value) => {
    handleAnswer(question._id, value);
  };

  const handleMouseEnter = (value) => {
    if (isDisabled) return;
    setHoverFill(value);
  };

  return (
    <div className="star">
      {[...Array(question.scaling_max)].map((_, index) => {
        const ratingValue = index + 1;

        return (
          <Tooltip title={isDisabled && disableTooltip ? disableTooltip : ""}>
            <button
              key={index}
              onMouseEnter={() => handleMouseEnter(ratingValue)}
              onMouseLeave={() => setHoverFill(null)}
              onClick={(e) => {
                e.preventDefault();
                handleRating(ratingValue);
              }}
              style={{
                background:
                  ratingValue <= (hoverFill || find_answer?.answer_statement)
                    ? "#B59D4E"
                    : "transparent",
                textAlign: "center",
              }}
              className="FaStar-button mb-2"
              disabled={isDisabled}
            >
              <p
                className="FaStar mb-0"
                style={{
                  color:
                    ratingValue <= (hoverFill || find_answer?.answer_statement)
                      ? "#ffffff"
                      : "#B59D4E",
                }}
              >
                {ratingValue}
              </p>
            </button>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default GeneralQuestionStarRating;
