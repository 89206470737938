import { Card, CircularProgress } from "@mui/material";
import React from "react";
import ReactVideoPlayer from "src/components/ReactPlayers/ReactVideoPlayer";
import { s3baseUrl } from "src/config/config";
import HiddenAudioPlayer from "./HiddenAudioPlayer";
import CountdownTimer from "./CountdownTimer";

const Background = ({ imageUrl }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      zIndex: 1,
    }}
  />
);

export default function BeforeStartMeeting(props) {
  const { moduleDetail, isStart, setIsTimePassed } = props;
  const {
    before_meeting_start_content,
    after_meeting_start_content,
    meeting_status,
  } = moduleDetail;

  return (
    <Card className="live-stream-wrapper">
      {meeting_status !== "ended" ? (
        <>
          {isStart ? (
            <div className="waiting-for-host">
              Waiting for the host to start the meeting{" "}
              <CircularProgress
                style={{
                  height: 16,
                  width: 16,
                  marginLeft: 5,
                }}
              />
            </div>
          ) : (
            <CountdownTimer
              moduleDetail={moduleDetail}
              setIsTimePassed={setIsTimePassed}
            />
          )}
          {isStart && after_meeting_start_content?.is_show_content ? (
            <>
              {after_meeting_start_content.content_type === "video" ? (
                <ReactVideoPlayer
                  url={after_meeting_start_content.video_link}
                  playingVideo={true}
                />
              ) : (
                <>
                  <Background
                    imageUrl={s3baseUrl + moduleDetail.image.thumbnail_1}
                  />
                  <HiddenAudioPlayer
                    fileUrl={s3baseUrl + after_meeting_start_content.file_url}
                  />
                </>
              )}
            </>
          ) : !isStart && before_meeting_start_content?.is_show_content ? (
            <>
              {before_meeting_start_content.content_type === "video" ? (
                <ReactVideoPlayer
                  url={before_meeting_start_content.video_link}
                  playingVideo={true}
                />
              ) : (
                <>
                  <Background
                    imageUrl={s3baseUrl + moduleDetail.image.thumbnail_1}
                  />
                  <HiddenAudioPlayer
                    fileUrl={s3baseUrl + before_meeting_start_content.file_url}
                  />
                </>
              )}
            </>
          ) : (
            <Background imageUrl={s3baseUrl + moduleDetail.image.thumbnail_1} />
          )}
        </>
      ) : (
        <>
          <div className="stream-video-available">
            <h2>This meeting has been ended</h2>
          </div>
          <Background imageUrl={s3baseUrl + moduleDetail.image.thumbnail_1} />
        </>
      )}
    </Card>
  );
}
