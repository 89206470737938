import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const addDynamiteDiaryApi = async (data) => {
  const requestObj = {
    path: `api/dynamite_diary/add_daily_diary`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addDynamiteDiaryMissionApi = async (data) => {
  const requestObj = {
    path: `api/mission/complete_growth_tool_action`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateDynamiteDiaryApi = async (id, data) => {
  const requestObj = {
    path: `api/dynamite_diary/update_diary/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const diaryListApi = async () => {
  const requestObj = {
    path: `api/dynamite_diary/list_member_diaries`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const diaryListWithDate = async (data) => {
  const requestObj = {
    path: `api/dynamite_diary/get_diary_with_date_or_id`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const move_to_tomorrow = async (data) => {
  const requestObj = {
    path: `api/dynamite_diary/move_to_tomorrow`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteDiaryApi = async (id) => {
  const requestObj = {
    path: `api/dynamite_diary/delete_diary/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_reminder_time_api = async (data) => {
  const requestObj = {
    path: `api/member/reminder_time/update`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
