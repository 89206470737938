import { invokeApi } from "../../bl_libs/invokeApi";

export const mission_levels_list_api = async () => {
  const requestObj = {
    path: `api/mission_level/list/for_members`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const mission_level_detail_api = async (mission_id) => {
  const requestObj = {
    path: `api/mission/detail/for_member/${mission_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const mission_level_detail_New_api = async (data) => {
  const requestObj = {
    path: `api/mission/detail_new`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const mission_level_detail_New_api_load_more = async (path, data) => {
  const requestObj = {
    path: path,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const onscreen_questions_api = async (data) => {
  const requestObj = {
    path: `api/questionnaire/add_comment_on_questionare`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const start_mission_api = async (mission_id) => {
  const requestObj = {
    path: `api/mission/start/${mission_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const played_content_api = async (mission_id) => {
  const requestObj = {
    path: `api/mission/update_watched_content/${mission_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const unlock_mission_schedule_api = async (schedule_id) => {
  const requestObj = {
    path: `api/mission/unlock_mission_schedule/${schedule_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const complete_mission_schedule_api = async (schedule_id, data) => {
  const requestObj = {
    path: `api/mission/complete_mission_schedule/${schedule_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const complete_actions_api = async (data) => {
  const requestObj = {
    path: `api/mission/complete_growth_tool_action`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_mission_schedule_data_api = async (schedule_id, data) => {
  const requestObj = {
    path: `api/mission/update_mission_schedule_data/${schedule_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
