// common business logic related util methods
import axios from "axios";
import { baseUri } from "../config/config";
import { decryptToken } from "src/utils/constants";

axios.defaults.headers.post["Content-Type"] = "application/json";

export async function invokeApi({
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  postData = {},
}) {
  const reqObj = {
    method,
    url: baseUri + path,
    headers,
  };
  if (headers["x-sh-auth"]) {
    if (
      headers["x-sh-auth"].startsWith("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9")
    ) {
      headers["x-sh-auth"] = headers["x-sh-auth"];
    } else {
      headers["x-sh-auth"] = decryptToken(headers["x-sh-auth"]);
    }
  }

  reqObj.params = queryParams;

  if (method === "POST") {
    reqObj.data = postData;
  }
  if (method === "PUT") {
    reqObj.data = postData;
  }
  if (method === "DELETE") {
    reqObj.data = postData;
  }

  let results;
  if (postData instanceof FormData) {
    console.log(...postData, "<===REQUEST-DATA===>");
  }

  console.log("<===REQUEST-OBJECT===>", reqObj);

  try {
    results = await axios(reqObj);
    console.log("<===Api-Success-Result===>", results);

    return results.data;
  } catch (error) {
    console.log("<===Api-Error===>", error.response);

    if (error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    return {
      code: error.response.status,
      message: error.response.data.message ? error.response.data.message : "",
      is_question_required:
        error.response.data.is_question_required &&
        error.response.data.is_question_required,
    };
  }
}
