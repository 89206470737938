import React from "react";
import { Icon } from "@iconify/react";
import RoadMapItem from "./RoadMapItem";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

export default function RoadMapSlider({ data }) {
  console.log(data, "data");
  const { userInfo } = useContentSetting();

  //getting upper records in which coins of user lies - includes [6k, 7k, ...] not included [500,2k] if user has more than 5k coins
  let greater_records = data.filter(
    (item) => item.coins > userInfo.coins_count
  );

  // getting minimun coins from given array just like getting 6k from [6k, 7k, ...]
  let max_coins_value = Math.min(...greater_records.map((obj) => obj.coins));

  // if selected value lies in first index
  if (greater_records.length < 1) {
    max_coins_value = data[data.length - 1].coins;
  }

  const handleSlideLeft = () => {
    if (document.getElementsByClassName("road-map-scroll").length > 0) {
      const scroll = -window.innerWidth * 0.8;
      document.getElementsByClassName("road-map-scroll")[0].scrollBy({
        left: scroll,
        behavior: "smooth",
      });
      setScrollValue(scroll);
    }
  };

  const handleSlideRight = () => {
    if (document.getElementsByClassName("road-map-scroll").length > 0) {
      const scroll = window.innerWidth * 0.8;
      document.getElementsByClassName("road-map-scroll")[0].scrollBy({
        left: scroll,
        behavior: "smooth",
      });
      setScrollValue(scroll);
    }
  };

  return (
    <section className="membership-section text-center mb-4">
      <div className="container">
        <div className="road-map-scroll text-start">
          <span className="road-map-scroll-left" onClick={handleSlideLeft}>
            <Icon className="road-map-scroll-icon" icon="ep:arrow-left-bold" />
          </span>
          <span className="road-map-scroll-right" onClick={handleSlideRight}>
            <Icon className="road-map-scroll-icon" icon="ep:arrow-right-bold" />
          </span>
          {data.map((reward_object, i) => {
            return (
              <RoadMapItem
                reward_object={reward_object}
                index={i}
                max_coins_value={max_coins_value}
                userInfo={userInfo}
                datalength={data}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
