import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ForgetPassword,
  Programmes,
  ProgrammesDetail,
  GoalStatement,
  Vault,
  Profile,
  Pods,
  Gratitude,
  Quotes,
  Memories,
  MemoriesDetail,
  Calender,
  SupportTicket,
  LessonsDetail,
  LessonListing,
  ProgrammRecordingDetail,
  AffirmationList,
  StudySessionRecordingDetail,
  SupportTicketDetail,
  ConsultantRecordings,
  Community,
  LoginAsCustomer,
  Welcome,
  CategoriesList,
  Chat,
  Members,
  Members2,
  AttitudeAssessment,
  AssessmentResult,
  Visioneering,
  Mediation,
  LifeScript,
} from "./pages/index";
import EditProfile from "./pages/Profile/EditProfile";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import { StudySessionListing } from "./components/programmes";
import PodsDetail from "./pages/Pods/PodsDetail";
import ManagePhotos from "./pages/GoalStatement/ManagePhotos";
import { Payment } from "./components/Payment/Payment";
import BillingCard from "./components/Billing/BillingCard";
import BillingDetail from "./components/Billing/BillingDetails";
import AllTransaction from "./components/Transactions/AllTransaction";
import TransactionInformation from "./components/Transactions/TransactionInformation";
import ManageCard from "./components/ChangeCard/ManageCard";
import ChangeCreditCard from "./components/ChangeCard/ChangeCreditCard";
import NintyDayPlan from "./pages/90DayPlan/NintyDayPlan";
import NintyDayTracker from "./pages/90DayPlan/NintyDayTracker";
import AddNewEarning from "./pages/90DayPlan/AddNewEarning";
import PaymentRequests from "./components/PaymentRequests/PaymentRequests";
import ActiveRequests from "./components/PaymentRequests/ActiveRequests";
import StudySessionRecordingListing from "./pages/StudySessions/StudySessionRecordingListing";
import VideoDetails from "./pages/Community/VideoDetails";
import PostDetails from "./pages/Community/PostDetails";
import FavouriteVideos from "./pages/Community/FavouriteVideos";
import LiveEvents from "./pages/LiveEvents/LiveEvents";
import LiveEventsDetail from "./pages/LiveEvents/LiveEventsDetail";
import LiveEventVideoDetails from "./pages/Community/LiveEventVideoDetails";
import MeditationCategories from "./pages/Meditations/MeditationCategories";
import MeditationsList from "./pages/Meditations/MeditationsList";
import MeditationDetail from "./pages/Meditations/MeditationDetail";
import Shop from "./pages/DDShop/Shop";
import ShopItemDetail from "./pages/DDShop/ShopItemDetail";
import ShopItemCheckout from "./pages/DDShop/ShopItemCheckout";
import ChallengeListAdd from "./pages/challenges/ChallengeListAdd";
import ShoppingCart from "./pages/DDShop/ShoppingCart";
import AssessmentQuestionsSection from "./pages/QuestionsScreen/Assessment";
import MinimalLayout from "./layouts/MinimalLayout";
import ThankYou from "./pages/QuestionsScreen/ThankYou";
import QuestionsAndAssessment from "./pages/QuestionsScreen/QuestionsAndAssessment";
import HabitsList from "./pages/Habits/HabitsList";
import DayWiseHabits from "./pages/Habits/DayWiseHabits";
import LoginByAdmin from "./pages/LoginByAdmin";
import LoginFromWebsite from "./pages/LoginFromWebsite";
import DynamiteDiary from "./pages/DynamiteDiary/DynamiteDiary";
import DiaryList from "./pages/DynamiteDiary/diarylist";
import MobilePlayer from "./pages/MobilePlayer";
import SeekVideoPlayer from "./components/ReactPlayers/SeekVideoPlayer";
import VisioneeringList from "./pages/Visioneering/VisioneeringList";
import GratitudeDaily from "./pages/GratitudeDaily/GratitudeDaily";
import GratitudeList from "./pages/GratitudeDaily/GratitudeList";
import MeditationList from "./pages/Mediation/MeditationList";
import PendingRequestsPayment from "./components/PaymentRequests/PendingRequestsPayment";
import NintyDayTrackerOld from "./pages/90DayPlan/NintyDayTrackerOld";
import Meditation from "./pages/Meditation/Meditation";
import MeditationPastList from "./pages/Meditation/MeditationList";
import DashboardApp from "./pages/DashboardApp";
import MediationChallenge from "./pages/Mediation/Mediation";
import MediationOld from "./pages/MediationChallenge/Mediation";
import LifesscriptList from "./pages/Life-Script/LifesscriptList";
import SourceSessionsListing from "./pages/SourceSession/SourceSessionsListing";
import SourceSessionsRecordingList from "./pages/SourceSession/SourceSessionsRecordingList";
import SourceSessionRecordingDetail from "./pages/SourceSession/SourceSessionRecordingDetail";
import PaymentRequestAuth from "./pages/PaymentRequestAuth/PaymentRequestAuth";
import FullPageLayout from "./layouts/FullPageLayout";
import GeneralQuestionsList from "./pages/GeneralQuestions/GeneralQuestionsList";
import QuestionThankYou from "./pages/QuestionThankYou/QuestionThankYou";
import LiveEventsLockedDescription from "./pages/LiveEvents/LiveEventsLockedDescription";
import CalendarEvents from "./pages/Calender/CalendarEvents";
import RequestVerification from "./components/PaymentRequests/RequestVerification";
import MobilePlayerEmbedCode from "./pages/MobilePlayerEmbedCode";
import AddCalendarEvents from "./pages/Calender/AddCalendarEvents";
import UpdateCalendarEvent from "./pages/Calender/UpdateCalendarEvent";
import ProgrammesModuleDetail from "./pages/Programmes/ProgrammesModuleDetail";
import ModulesLessonListing from "./pages/Programmes/ModulesLessonListing";
import SelfImage from "./pages/GoalStatement/SelfImage";
import StripeCardChange from "./components/ChangeCard/CardProvider";
import TutorialDetail from "./components/Tutorials/TutorialDetail";
import TutorialsList from "./components/Tutorials/TutorialsList";
import NotificationsSetting from "./pages/Profile/NotificationsSetting";
import QRScanner from "./pages/Profile/QRScanner";
import CancelSubscription from "./pages/CancelSubscription";
import BookingsList from "./pages/bookings/BookingsList";
import ActiveSubscriptionList from "./components/PaymentRequests/ActiveSubscriptionList";
import CheckinUser from "./pages/Profile/CheckinUser";
import EmailTemplateForPayment from "./layouts/dashboard/EmailTemplateForPayment";
import RoadMapSlider from "./components/_dashboard/RoadMapSlider";
import AgreementPage from "./pages/Agreement/Agreement";
import ShopCart from "./pages/DDShop/ShopCart";
import ShopCheckOut from "./pages/DDShop/ShopCheckOut";
import CheckOutFile from "./pages/DDShop/CheckOutFileFinal";
import TotalSales from "./components/_dashboard/app/TotalSales";
import ShopCatagory from "./pages/DDShop/ShopCatagory";
import ShopThanskPage from "./pages/DDShop/ShopThanskPage";
import MentorShip from "./pages/MentorShip/MentorShip";
import MentorShipVideos from "./pages/MentorShip/MentorShipVideos";
import MobileAppLayout from "./layouts/MobileAppLayout";
import MainPods from "./pages/Pods/MainPods";
import TermsAndConditions from "./pages/TermsAndConditions";
import OrderHistorysDetail from "./pages/DDShop/OrderHistorysDetail";
import OrderHistory from "./pages/DDShop/OrderHistory";
import MentorshipPayment from "./pages/MentorShip/MentorshipPayment";
import AllLeaderBoards from "./pages/Community/AllLeaderBoards";
import YourStates from "./pages/States/YourStates";
import SourceLibrary from "./pages/SourceLibrary/SourceLibrary";
import GapsVideos from "./pages/GapsVideos/GapsVideos";
import MissionConTrollNew from "./pages/MissionConTrollNew/MissionControl";
import MissionControl from "./pages/MissionConTrollNew/MissionControl";
import AllHistories from "./pages/90DayPlan/AllHistories";
import AllHistoriesQuestions from "./pages/90DayPlan/AllHistoriesQuestions";
import WebPageSetting from "./pages/MissionControl/WebPageSetting";
import MissionLevelDetail from "./pages/MissionLevels/MissionLevelDetail";
import MissionDetailNew from "./pages/MissionLevels/MissionDetailNew";
import MissionLevels from "./pages/MissionLevels/MissionLevels";
import ScheduleDetails from "./pages/MissionLevels/ScheduleDetails";
import LiveStreaming from "./pages/Live-Stream/LiveStreaming";
import HlsVideoPlayer from "./pages/Live-Stream/components/HlsVideoPlayer";
import HtmlRecorder from "./pages/Live-Stream/HtmlRecorder";
import VideoEncoderComponent from "./pages/Live-Stream/VideoEncoderComponent";
import RecordFrame from "./pages/Live-Stream/RecordFrame";
import RLIveStream from "./pages/Live-Stream/RLIveStream";
import JoinMeeting from "./pages/Live-Stream/components/JoinMeeting";
import UnSubscribe from "./pages/UnSubscribe/UnSubscribe";
import { decryptToken, encryptToken } from "./utils/constants";
import LiveEventsDetailNew from "./pages/LiveEvents/LiveEventsDetailNew";
import CatagoriesDetailScreen from "./pages/LiveEvents/CatagoriesDetailScreen";
// ----------------------------------------------------------------------

const Authentication = () => {
  if (localStorage.getItem("token")) {
    if (
      localStorage
        .getItem("token")
        .startsWith("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9")
    ) {
      localStorage.setItem(
        "token",
        encryptToken(localStorage.getItem("token"))
      );
    }
    return <Navigate to="/mission-control"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};

const MoveToMissionControl = () => {
  return <Navigate to="/mission-control"> </Navigate>;
};
const MoveToLiveEvents = () => {
  return <Navigate to="/live-events"> </Navigate>;
};

export default function Router() {
  return (
    <Routes>
      {/* For Mobile Side */}
      <Route element={<MinimalLayout />}>
        <Route path="/live-stream/:topic/:token" element={<LiveStreaming />} />
        <Route path="/join-meeting/:meeting_id" element={<JoinMeeting />} />
        <Route path="/unsubscribe" element={<UnSubscribe />} />
      </Route>
      <Route path="/login-by-admin/:token" element={<LoginByAdmin />} />
      <Route path="/login-by-web/:token" element={<LoginFromWebsite />} />
      <Route
        path="/request-verification/:token"
        element={<RequestVerification />}
      />
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/manage-subscription" element={<CancelSubscription />} />
        <Route path="/HtmlRecorder" element={<HtmlRecorder />} />
        <Route path="/RecordFrame" element={<RecordFrame />} />
        <Route path="/RLIveStream" element={<RLIveStream />} />
        <Route
          path="/VideoEncoderComponent"
          element={<VideoEncoderComponent />}
        />
        <Route path="/HlsVideoPlayer" element={<HlsVideoPlayer />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route
          path="/new-mission-control"
          element={<MissionControl feeds_type="general" />}
        />
        <Route path="/mission-levels" element={<MissionLevels />} />
        <Route
          path="/mission-levels/level-detail/:mission__id"
          element={<MissionLevelDetail />}
        />{" "}
        <Route
          path="/mission-levels/level-details/:mission__id"
          element={<MissionDetailNew />}
        />{" "}
        <Route
          path="/mission-levels/schedule-details/:created_for_id/:schadule_id"
          element={<ScheduleDetails />}
        />
        <Route
          path="/manage-subscription/:id"
          element={<ActiveSubscriptionList />}
        />
        <Route path="/manage-notification" element={<NotificationsSetting />} />
        <Route path="/qr-scanner" element={<QRScanner />} />
        <Route path="/manage-card" element={<ManageCard />} />
        {/* <Route path="/edit-profile" element={<EditProfile />} /> */}
        <Route path="/transaction" element={<AllTransaction />} />
        <Route
          path="/pending-request/:request_slug"
          element={<PendingRequestsPayment />}
        />
        <Route path="/transaction/:request_slug" element={<ActiveRequests />} />
        <Route path="/payment-request" element={<PaymentRequests />} />
        <Route path="/payment-request/accepted" element={<PaymentRequests />} />
        <Route path="/payment-request/pending" element={<PaymentRequests />} />
        <Route
          path="/active-request/:request_slug"
          element={<ActiveRequests />}
        />{" "}
        <Route path="/dashboard" element={<MoveToMissionControl />} />
        <Route path="/dashboard-new" element={<MoveToMissionControl />} />
        <Route
          path="/mission-control"
          element={<DashboardApp feeds_type="general" />}
        />
        <Route
          path="/new-mission-control"
          element={<MissionConTrollNew feeds_type="general" />}
        />
        <Route path="/road-map-slider" element={<RoadMapSlider />} />
        <Route path="/states" element={<YourStates />} />
        <Route path="/source-library" element={<SourceLibrary />} />
        <Route path="/TestVideoPlayer" element={<SeekVideoPlayer />} />
        <Route path="/email-template" element={<EmailTemplateForPayment />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route
          path="/thesource"
          element={<Community feeds_type="general" event_id="" />}
        />
        <Route path="/leader-boards" element={<AllLeaderBoards />} />
        <Route path="/series-video/:video_id" element={<VideoDetails />} />
        <Route
          path="/mission-control/series-video/:video_id"
          element={<VideoDetails />}
        />
        <Route
          path="/series-video/favourite-videos"
          element={<FavouriteVideos />}
        />
        <Route
          path="/feed-detail/:post_id"
          element={<PostDetails feeds_type="general" />}
        />{" "}
        <Route
          path="/mission-levels/level-details/:misssion_id/feed-detail/:post_id"
          element={<PostDetails feeds_type="mission" />}
        />
        <Route path="/programmes" element={<Programmes />} />
        <Route path="/gaps-videos" element={<GapsVideos />} />
        <Route path="/gaps-videos/:created_for_id" element={<GapsVideos />} />
        <Route path="/mentorship" element={<MentorShip />} />
        <Route
          path="/mentorship/:category_slug"
          element={<MentorShipVideos />}
        />
        <Route
          path="/mentorship-payment/:category_slug/:plan_id"
          element={<MentorshipPayment />}
        />
        <Route path="/programmes/:slug" element={<ProgrammesDetail />} />
        <Route
          path="/programmes/:slug/feed-detail/:post_id"
          element={<PostDetails feeds_type="program" />}
        />
        <Route
          path="/programmes/:slug/:tab_slug"
          element={<ProgrammesDetail />}
        />
        <Route path="/ninety-day-plan" element={<NintyDayPlan />} />
        <Route
          path="/ninety-day-plan/previous-histories"
          element={<AllHistoriesQuestions />}
        />
        <Route path="/self-image" element={<SelfImage />} />
        <Route path="/agreement/:id" element={<AgreementPage />} />
        <Route
          path="/self-image/manage-photos"
          element={<ManagePhotos type="self_image_gallery" />}
        />
        {/* <Route path="/manage-subscription" element={<CancelSubscription />} /> */}
        {/* <Route
          path="/manage-subscription/:id"
          element={<ActiveSubscriptionList />}
        /> */}
        <Route path="/ninety-day-tracker" element={<NintyDayTracker />} />
        <Route
          path="/ninety-day-tracker/previous-histories"
          element={<AllHistories />}
        />
        <Route path="/ninety-day-tracker1" element={<NintyDayTrackerOld />} />
        <Route path="/addnewearning" element={<AddNewEarning />} />
        <Route
          path="/programmes/:program_slug/lessons"
          element={<LessonListing />}
        />
        <Route
          path="/programmes/modules/:program_slug/lessons"
          element={<ModulesLessonListing />}
        />
        <Route path="/study-session" element={<StudySessionListing />} />
        <Route path="/source-session" element={<SourceSessionsListing />} />
        <Route
          path="/meditation-categories"
          element={<MeditationCategories />}
        />
        <Route path="/live-events" element={<LiveEvents />} />
        {/* <Route path="/live-events/:id" element={<LiveEventsDetailNew />} /> */}
        <Route path="/live-events/:id" element={<LiveEventsDetail />} />
        <Route path="/live-events/new/:id" element={<LiveEventsDetail />} />
        <Route
          path="/live-events/category/:event_id/:category_id"
          element={<CatagoriesDetailScreen />}
        />
        <Route
          path="/live-events-locked/:id"
          element={<LiveEventsLockedDescription />}
        />
        <Route
          path="/live-events/video/question-answers/:created_for_id"
          element={
            <GeneralQuestionsList
              created_for="dynamite_event_video"
              heading="Dynamite Event Video Questions"
            />
          }
        />
        <Route
          path="/live-events/video/question-answers/:created_for_id/thank-you"
          element={<QuestionThankYou created_for="dynamite_event_video" />}
        />
        <Route
          path="/live-events/:id/:category_slug"
          element={<LiveEventsDetail />}
        />
        <Route
          path="/live-events/:event_id/video-detail/:video_id"
          element={<LiveEventVideoDetails />}
        />
        <Route
          path="/live-events/:event_id/feed-detail/:post_id"
          element={<PostDetails feeds_type="event" />}
        />
        {/* <Route path="/gratitude-daily" element={<GratitudeDaily />} /> */}
        <Route path="/gratitude-daily" element={<GratitudeDaily />} />
        <Route
          path="/gratitude-daily/add-gratitude-daily"
          element={<GratitudeDaily />}
        />
        <Route
          path="gratitude-daily/edit-gratitude-daily/:id"
          element={<GratitudeDaily />}
        />
        <Route
          path="/gratitude-daily/past-activities"
          element={<GratitudeList />}
        />
        <Route path="/tutorials" element={<TutorialsList />} />
        <Route
          path="/tutorials/tutorial-detail/:id"
          element={<TutorialDetail />}
        />
        <Route
          path="/study-session/:sesson_slug/study-session-recordings"
          element={<StudySessionRecordingListing />}
        />
        <Route
          path="/source-session/:sesson_id/source-session-recordings"
          element={<SourceSessionsRecordingList />}
        />
        <Route
          path="/meditation-categories/:category_slug/meditations"
          element={<MeditationsList />}
        />
        {/* <Route path="/manage-notification" element={<NotificationsSetting />} /> */}
        {/* <Route path="/qr-scanner" element={<QRScanner />} /> */}
        <Route path="/meditations" element={<MeditationList />} />
        <Route path="/money-script" element={<LifesscriptList />} />
        <Route path="/money-script/:id" element={<LifeScript />} />
        {/* <Route path="/meditations/:meditation_id" element={<MediationOld />} /> */}
        <Route
          path="/meditations/:meditation_id"
          element={<MediationChallenge />}
        />
        <Route
          path="/meditation-categories/:category_slug/meditations/:meditation_slug"
          element={<MeditationDetail />}
        />
        <Route
          path="/study-session/:sesson_slug/study-session-recording/:recording_slug"
          element={<StudySessionRecordingDetail />}
        />
        <Route
          path="/source-session/:sesson_id/source-session-recording/:recording_slug"
          element={<SourceSessionRecordingDetail />}
        />
        <Route path="/lessons/:lesson_slug" element={<LessonsDetail />} />
        <Route
          path="/programmes/modules/:slug"
          element={<ProgrammesModuleDetail />}
        />
        <Route
          path="/lessons/questions/:created_for_id"
          element={
            <GeneralQuestionsList
              created_for="lesson"
              heading="Lesson Questions"
            />
          }
        />
        <Route
          path="/lessons/questions/:created_for_id/thank-you"
          element={<QuestionThankYou created_for="lesson" />}
        />
        <Route
          path="/recordings/:recording_slug"
          element={<ProgrammRecordingDetail />}
        />
        <Route
          path="/lessons-recordings/:recording_slug"
          element={<ProgrammRecordingDetail />}
        />
        <Route path="/goal-statement" element={<GoalStatement />} />
        {/* chat routes */}
        <Route path="/chat" element={<Chat />} />
        {/* ----------- */}
        <Route path="/vault" element={<Vault />} />
        <Route
          path="/consultant-recordings"
          element={<ConsultantRecordings />}
        />
        <Route path="/payment" element={<Payment />} />
        {/* <Route path="/payment-request/accepted" element={<PaymentRequests />} />
        <Route path="/payment-request/pending" element={<PaymentRequests />} /> */}
        {/* <Route path="/payment-request" element={<PaymentRequests />} /> */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/Billing" element={<BillingCard />} />
        {/* <Route path="/change-card" element={<ChangeCreditCard />} /> */}
        <Route path="/change-card" element={<StripeCardChange />} />
        {/* <Route path="/transaction" element={<AllTransaction />} /> */}
        <Route
          path="/transaction/transaction-detail/:slug"
          element={<TransactionInformation />}
        />
        {/* <Route path="/manage-card" element={<ManageCard />} /> */}
        <Route
          path="/billing/billing-detail/:billslug"
          element={<BillingDetail />}
        />
        <Route path="/edit-profile" element={<EditProfile />} />
        {/* <Route path="/pods" element={<Pods />} /> */}
        <Route path="/pods" element={<MainPods />} />
        <Route path="/pods/expire" element={<MainPods />} />
        <Route path="/affirmations" element={<CategoriesList />} />
        <Route
          path="/affirmations/affirmation-list/:category_slug"
          element={<AffirmationList />}
        />
        <Route path="/pods/pods-detail/:pod_slug" element={<PodsDetail />} />
        {/* <Route
          path="/pending-request/:request_slug"
          element={<PendingRequestsPayment />}
        /> */}
        <Route
          path="/pending-request/:request_slug/:type"
          element={<PendingRequestsPayment />}
        />
        {/* <Route path="/transaction/:request_slug" element={<ActiveRequests />} /> */}
        <Route path="dynamite-diary" element={<DynamiteDiary />} />
        <Route path="dynamite-diary/past-activities" element={<DiaryList />} />
        <Route
          path="dynamite-diary/add-dynamite-diary"
          element={<DynamiteDiary />}
        />
        <Route
          path="dynamite-diary/edit-dynamite-diary/:id"
          element={<DynamiteDiary />}
        />
        <Route path="/inception" element={<MoveToLiveEvents />} />
        <Route
          path="/goal-statement/manage-photos"
          element={<ManagePhotos type="goal_statement_gallery" />}
        />
        {/* <Route path="/gratitude" element={<Gratitude />} /> */}
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/members" element={<Members />} />
        <Route path="/questions/assessment/thank-you" element={<ThankYou />} />
        <Route path="/members2" element={<Members2 />} />
        <Route path="/memories" element={<Memories />} />
        <Route path="/assessment" element={<AttitudeAssessment />} />
        <Route path="/assessment/results" element={<AssessmentResult />} />
        <Route
          path="/visioneering/:visioneering_id"
          element={<Visioneering />}
        />
        <Route path="/visioneering" element={<VisioneeringList />} />
        <Route path="/questions" element={<QuestionsAndAssessment />} />
        <Route path="/mediation" element={<Meditation />} />
        <Route path="/mediation/edit-meditation/:id" element={<Meditation />} />
        <Route
          path="/mediation/past-activities"
          element={<MeditationPastList />}
        />
        <Route path="/mediation/:meditation_id" element={<Mediation />} />
        <Route
          path="/questions/assessment"
          element={<AssessmentQuestionsSection />}
        />
        <Route
          path="/memories/memories_detail/:memory_slug"
          element={<MemoriesDetail />}
        />
        <Route path="/calender" element={<Calender />} />
        <Route path="/calendar-events" element={<CalendarEvents />} />
        <Route
          path="/calendar-events/add-event"
          element={<AddCalendarEvents />}
        />
        <Route
          path="/calendar-events/:event_slug/:iteration_id"
          element={<UpdateCalendarEvent />}
        />
        <Route path="/support-ticket" element={<SupportTicket />} />
        <Route path="/support-ticket/:id" element={<SupportTicketDetail />} />
        <Route path="/shop/product/:id" element={<Shop />} />
        <Route path="/shop" element={<ShopCatagory />} />
        <Route path="/bookings" element={<BookingsList />} />
        <Route path="/ShopCart" element={<ShopCart />} />
        {/* <Route path="/order-history" element={<OrderHistorysDetail />} /> */}
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/shop/:item_slug" element={<ShopItemDetail />} />
        <Route path="/shop/shopping-cart" element={<ShoppingCart />} />
        <Route path="/shop/shopping-checkout" element={<CheckOutFile />} />
        <Route
          path="/shop/shopping-checkout/:order_id"
          element={<CheckOutFile />}
        />
        <Route path="/shop/Thansk-you-page" element={<ShopThanskPage />} />
        <Route
          path="/shop/checkouts/:item_slug"
          element={<ShopItemCheckout />}
        />
        <Route path="/challenge" element={<ChallengeListAdd />} />
        <Route path="/challenge/:challenge_id" element={<ChallengeListAdd />} />
        <Route path="/habits-tracker" element={<HabitsList />} />
        <Route
          path="/habits-tracker/day-wise-habits"
          element={<DayWiseHabits />}
        />
        <Route path="/sales" element={<TotalSales />} />
      </Route>
      <Route element={<MobileAppLayout />}>
        {/* this layout is using full page web view for DD mobile application */}
        <Route path="/mobile-player/:url" element={<MobilePlayer />} />
        <Route path="/mobile-play/:code" element={<MobilePlayerEmbedCode />} />
      </Route>
      <Route element={<FullPageLayout />}>
        <Route
          path="/payment-request-auth/:request_slug"
          element={<PaymentRequestAuth />}
        />
        <Route path="/check-in/:token" element={<CheckinUser />} />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/login-by-email"
          element={<Login login_by_email={true} />}
        />
        <Route path="/login-as-customer" element={<LoginAsCustomer />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
