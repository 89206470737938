import React, { useEffect, useRef, useState } from "react";
import Peer from "peerjs";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CircularProgress, Fab, Tooltip } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { formatTime } from "src/utils/constants";
import moment from "moment";
const momenttz = require("moment-timezone");
import Hls from "hls.js";
import HlsVideoPlayer from "../../../Live-Stream/components/HlsVideoPlayer";

const Background = ({ imageUrl }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    }}
  />
);

export default function VideoStream(props) {
  const {
    feedDetail,
    is_detail_page,
    handleCloseDetailBox,
    liveStream,
    isFullScreen,
    setIsFullScreen,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const { socket, userInfo } = useContentSetting();
  const params = useParams();
  const navigate = useNavigate();
  let total_time = 0;
  if (liveStream?.stream_start_time) {
    const startTime = moment(liveStream.stream_start_time);
    const endTime = momenttz.tz(new Date(), "Europe/Dublin");
    total_time = endTime.diff(startTime, "seconds");
  }
  const [timer, setTimer] = useState(total_time);

  const manage_stream_participant = (action) => {
    const data = {
      user_id: userInfo._id,
      module_id: feedDetail._id,
      action: action,
    };
    socket.emit("manage_stream_participant", data);
  };

  // useEffect(() => {
  //   let interval;
  //   interval = setInterval(() => {
  //     setTimer((prevTimer) => prevTimer + 1);
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    manage_stream_participant("user_joined");
    return () => {
      manage_stream_participant("user_left");
    };
  }, [feedDetail._id, socket, userInfo]);

  const handleChangePage = () => {
    if (is_detail_page) {
      if (!params.event_id) {
        navigate(`/thesource`);
      } else if (params.event_id) {
        navigate(`/live-events/${params.event_id}`);
      } else {
        navigate(-1);
      }
    } else {
      handleCloseDetailBox();
    }
  };

  return (
    <Card className="live-stream-wrapper">
      {isLoading && (
        <CircularProgress className="live-stream-loader" color="primary" />
      )}
      <HlsVideoPlayer
        hlsUrl={liveStream.stream_end_point_url}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        handleChangePage={handleChangePage}
      />
    </Card>
  );
}
