import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
import {
  get_member_event_detail_for_member,
  live_events_list_api,
} from "src/DAL/LiveEvents/LiveEvents";
import CatagoryChatCard from "./components/CatagoryChatCard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CatagoriesDetailScreen({ categories }) {
  const classes = useStyles();
  const { event_id, category_id } = useParams();
  const [categoryDetail, setCategoryDetail] = useState(null);
  const [videosList, setVideosList] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const breadCrumbMenu = [
    {
      title: categoryDetail?.title,
      navigation: `/live-events/${event_id}`,
      active: false,
    },
    {
      title: categoryDetail?.dynamite_event_category?.title,
      navigation: "",
      active: true,
    },
  ];
  const getCategoryDetail = async () => {
    let data = {
      dynamite_event_id: event_id,
      event_category_id: category_id,
    };
    const result = await get_member_event_detail_for_member(data);
    if (result.code === 200) {
      setCategoryDetail(result.member_dynamite_event);
      const videos =
        result?.member_dynamite_event?.dynamite_event_category?.videos;
      setVideosList(videos);
      if (videos && videos.length > 0) {
        setSelectedVideo(videos[0]); // Set the first video as the default selected video
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSelectedVideo = (video) => {
    setShowChat(false);
    setSelectedVideo(video);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    getCategoryDetail();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
      </div>
      {selectedVideo ? (
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>{selectedVideo?.title}</h2>
          </div>
          <div
            className={`col-12 ${showChat ? "col-md-8" : ""}`}
            id={selectedVideo?._id}
          >
            <div>
              <div className="col-12 mb-3 mt-2">
                {selectedVideo?.video_url && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedVideo.video_url,
                    }}
                  ></div>
                )}
                {selectedVideo?.video_url === "" && (
                  <img
                    src={s3baseUrl + selectedVideo.image.thumbnail_1}
                    alt=""
                    srcSet=""
                  />
                )}
              </div>
              {selectedVideo?.audio_file_url &&
                selectedVideo.video_type === "audio" && (
                  <div className="col-12 mt-3 text-center">
                    <div>
                      <audio
                        controlsList="nodownload"
                        className="w-100"
                        src={s3baseUrl + selectedVideo.audio_file_url}
                        controls
                      />
                    </div>
                  </div>
                )}
              <div className="col-12 mt-3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedVideo?.detail_description,
                  }}
                ></div>
              </div>
            </div>
          </div>
          {showChat && (
            <div className="col-12 col-md-4 mt-md-3">
              <CatagoryChatCard video={selectedVideo} handleOpenLikes={null} />
            </div>
          )}
          <div className="col-6 mt-3">
            <h2>Videos</h2>
          </div>
          <div className="col-6 text-end mt-3">
            <div className="d-flex justify-content-end">
              {/* {handleAskQuestion(video) && (
              <button
                className="ms-3 small-contained-button text-nowrap"
                onClick={() => handleVideoQuestions(video)}
              >
                {video?.question_configration?.button_text}
              </button>
            )} */}
              {selectedVideo?.is_chat_enable && (
                <button
                  onClick={() => setShowChat(!showChat)}
                  className="ms-3 small-contained-button text-nowrap"
                >
                  {` ${showChat ? "Hide" : "Show"}`} Chat
                </button>
              )}
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="row">
              {videosList.map((video) => (
                <div
                  className="col-12 col-md-4 col-lg-4   cursor-pointer"
                  onClick={() => handleSelectedVideo(video)}
                >
                  <div
                    className={`card-vedio position-relative ${
                      selectedVideo?._id === video._id
                        ? "card-vedio-highlight"
                        : ""
                    }`}
                  >
                    <div className="video_img_dev position-relative">
                      <img
                        src={s3baseUrl + video.image.thumbnail_1}
                        className="img_width w-100"
                        style={{
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                      />
                    </div>
                    <div className="p-2 pt-3">
                      <h3 style={{ fontSize: "14px", fontWeight: "600" }}>
                        {video.title}
                      </h3>
                      <p style={{ fontSize: "12px" }}>
                        {video.short_description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5">
          <RecordNotFound />
        </div>
      )}
    </div>
  );
}
